<template>
  <Box> 
  <!-- main container -->
  
    <Row>
      <Column :width="5" class="rc-text-value">
        {{ this.checkin.employee().find().user().find().fullName() }}
      </Column>
      <Column :width="5" class="rc-text-value rc-text-centre">
        {{ this.checkin.createdDateDisplay() }}
      </Column>
      <Column :width="1" />
      <Column :class="toResultColour()">
        {{ this.checkin.resultDisplay() }}
      </Column>
      <Column :width="1" />
      
    </Row>
  
    <StringValueRow v-if="isAdmin" label="Company" :value="this.checkin.company().find().name()"/>
    <StringValueRow v-if="isAdmin" label="Id" :value="this.checkin.id()"/>
  <!-- main container -->
  </Box> 
</template>

<script>
import { mapGetters } from "vuex";
import Catalog from "@/domain/session/Catalog.js";
import CheckIn from "@/domain/model/checkin/CheckIn.js";
//import Button   from '@/components/button/Button.vue';
import StringValueRow from "@/components/row/StringValueRow.vue";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'portal-operator-checkin-row',
  components: {
//    Button,
    StringValueRow,
    Box, Column, Row,
  },
  props: {
    checkinData: Object,
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      C: new Catalog(),
    }
  },
  computed: {
    ...mapGetters([
         'auth_connected',
         'domain',
      ]),
    checkin: function() {
      if (this.checkinData) {
        return new CheckIn(this.domain, this.checkinData);
      }
      return new CheckIn(this.domain, {});
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    toResultColour: function () {
      var result = this.checkin.result();
      var css = "rc-box-col-3 rc-text-value rc-text-centre "
      if (result == "None") {
        return css + "rc-input-good";
      }
      if (result == "Minor") {
        return css + "rc-input-minor";
      }
      if (result == "Major") {
        return css + "rc-input-major";
      }
      return css;
    },

  },
}
</script>